<template>
  <div>
    <search></search>
    <tableComponent></tableComponent>
  </div>
</template>
<script>
  /***
   Name: index
   Code: sa0ChunLuyu
   Time: 2021/11/25 20:21
   Remark: 样品管理
   */
  import search from "./search/search.vue";
  import tableComponent from "./table/table.vue"

  export default {
    components: {search, tableComponent},
    data() {
      return {}
    },
  }
</script>
<style scoped>

</style>
