<script>
/***
 Name: table/table
 Code: sa0ChunLuyu
 Time: 2021/11/26 14:38
 Remark: 样品列表部分
 */
export default {
  data() {
    return {}
  },
  watch: {
    $route: {
      handler() {
        this.mountedDo()
      },
      deep: true
    }
  },
  mounted() {
    this.mountedDo()
  },
  methods: {
    mountedDo() {
      this.page_config = {
        task_id: Number(this.$route.query.task_id) ? Number(this.$route.query.task_id) : 0,
        status: Number(this.$route.query.status) ? Number(this.$route.query.status) : 0,
        search: this.$route.query.search ? this.$route.query.search : '',
      }
      setTimeout(() => {
        this.page_show = true
      })
      this.getSampleList()
    },
  }
}
</script>
<template>
  <div>
    table/table
  </div>
</template>
<style scoped>

</style>
