<script>
/***
 Name: search/search
 Code: sa0ChunLuyu
 Time: 2021/11/25 20:33
 Remark: 搜索栏
 */
export default {
  data() {
    return {
      page_config: {
        task_id: 0,
        status: 0,
        search: ''
      },
      task_list: [],
      page_show: false
    }
  },
  watch: {
    $route:{
      handler() {
        this.mountedDo()
      },
      deep: true
    },
    page_config: {
      handler() {
        if (this.page_show) this.pageJump()
      },
      deep: true
    }
  },
  mounted() {
    this.mountedDo()
  },
  methods: {
    pageJump() {
      this.$router.push({
        query: {
          page: 1,
          ...this.page_config
        }
      })
    },
    mountedDo() {
      this.page_config = {
        task_id: Number(this.$route.query.task_id) ? Number(this.$route.query.task_id) : 0,
        status: Number(this.$route.query.status) ? Number(this.$route.query.status) : 0,
        search: this.$route.query.search ? this.$route.query.search : '',
      }
      setTimeout(() => {
        this.page_show = true
      })
      this.getTaskList()
    },
    getTaskList() {
      // 采集和接收人获取和自己相关的任务
      this.$sa0.post({
        url: this.$api('采集和接收人获取和自己相关的任务')
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.task_list = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    }
  }
}
</script>
<template>
  <div>
    <div>
      <a-space>
        <div><b>委托单：</b></div>
        <div style="width: 200px">
          <a-select class="w-full" v-model="page_config.task_id">
            <a-select-option :value="0">全部</a-select-option>
            <a-select-option :value="i.id" v-for="(i,k) in task_list">{{ i.name }}</a-select-option>
          </a-select>
        </div>
        <div class="ml-4"><b>状态：</b></div>
        <div style="width: 200px">
          <a-select class="w-full" v-model="page_config.status">
            <a-select-option :value="0">全部</a-select-option>
            <a-select-option :value="1">采集待处理</a-select-option>
            <a-select-option :value="2">采集中</a-select-option>
            <a-select-option :value="3">采集完成</a-select-option>
            <a-select-option :value="4">待接收</a-select-option>
            <a-select-option :value="5">接收完成</a-select-option>
          </a-select>
        </div>
        <div class="ml-4"><b>名称/编号：</b></div>
        <div>
          <a-input v-model="page_config.search" placeholder="名称/编号"/>
        </div>
      </a-space>
    </div>
  </div>
</template>
<style scoped>

</style>
